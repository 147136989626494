import React, {useEffect, useState} from "react";
import {apiGet, formatDate} from "../utils";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {Typography} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import {ContractHistoryTable} from "../components/ContractHistoryTable";

export const HistoryContainer = () => {
    const [allPlayers, setAllPlayers] = useState([]);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [playerHistory, setPlayerHistory] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchAllPlayers = () => {
        apiGet(`player/all`, (data) => {
            setAllPlayers(data.data);
        });
    };

    useEffect(() => {
        fetchAllPlayers();
    }, []);

    useEffect(() => {
        if (selectedPlayer) {
            setLoading(true);
            console.log(`selectedPlayer: ${JSON.stringify(selectedPlayer, null, 2)}`);
            apiGet(`contract/history/${selectedPlayer.yahoo_id}`, (data) => {
                const contractHistory = {};
                if(data.data) {
                    for (const c of data.data) {
                        if (!contractHistory[c.key]) contractHistory[c.key] = [];
                        const pickupType = c.previous_contract_key ? 'RFA kept' : 'Signed';
                        const salary = c.signing_salary > 0 ? c.signing_salary : '?'
                        contractHistory[c.key].push(`${formatDate(c.signed_date)} - ${pickupType} by ${c.signing_manager} for ${salary}|${c.signed_year}|${c.signed_week}`);
                        if (c.trade_key) {
                            contractHistory[c.key].push(`${formatDate(c.traded_date)} - Traded from ${c.from_manager} to ${c.to_manager}|${c.traded_year}|${c.traded_week}`);
                        }
                        if (c.dropped_year) {
                            const dropType = c.dropped_year - c.signed_year === 3 ? 'Expired' : 'Dropped';
                            contractHistory[c.key].push(`${formatDate(c.dropped_date)} - ${dropType}|${c.dropped_year}|${c.dropped_week}`);
                        }
                    }
                    for (const [key, eventStrings] of Object.entries(contractHistory)) {
                        contractHistory[key] = [...new Set(eventStrings)];
                        contractHistory[key].sort();
                    }
                }
                setPlayerHistory(contractHistory);
                setLoading(false);
            });
        }
    }, [selectedPlayer]);

    const onPlayerChange = (event, value) => {
        setSelectedPlayer(value);
    };

    return (
        <Grid container justifyContent="center">
            <div style={{margin: 50}}>
                <Typography variant="h6" color="inherit" display="inline" style={{marginLeft: 10}}>
                    Player:
                </Typography>
                <Autocomplete id="playerSelectionField" freeSolo style={{width: 500}} options={allPlayers}
                              getOptionLabel={(p) => `${p.first_name} ${p.last_name} (${p.pos} - ${p.team})`}
                              onChange={onPlayerChange}
                              renderInput={(params) => (
                                  <TextField {...params} label="Search" margin="normal" variant="outlined"/>
                              )}/>
                {loading && <LinearProgress color="secondary"/>}
                {playerHistory && <ContractHistoryTable playerHistory={playerHistory}/>}
            </div>
        </Grid>
    );
}