import React, {useEffect, useState} from "react";
import {config} from "../config";
import {apiGet} from "../utils";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";

export const FooterContainer = () => {
    const [apiVersion, setApiVersion] = useState(null);

    const fetchApiVersion = () => {
        apiGet(`version`, (data) => {
            setApiVersion(data.version);
        });
    };

    useEffect(() => {
        fetchApiVersion();
    }, []);

    return (
        <div style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%'
        }}>
            <AppBar position="sticky" style={{height: 40}}>
                <Grid container justifyContent="center">
                    <Typography>UI version: {config.uiVersion} - API version: {apiVersion}</Typography>
                </Grid>
            </AppBar>
        </div>
    );
}