import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

export const ContractHistoryTable = ({playerHistory}) => {
    if(Object.keys(playerHistory).length > 0) {
        return (
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Contract</TableCell>
                            <TableCell>Event</TableCell>
                            <TableCell>Season</TableCell>
                            <TableCell>Week</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(playerHistory).map((contractKey, contractIndex) => {
                            const eventStrings = playerHistory[contractKey];
                            return eventStrings.map((eventString, eventIndex) => {
                                const rowKey = `row-${contractKey}-${eventString}`;
                                return <TableRow key={rowKey}>
                                    {eventIndex === 0 &&
                                        <TableCell rowSpan={eventStrings.length}
                                                   style={{verticalAlign: 'top'}}>{contractIndex + 1}</TableCell>
                                    }
                                    {eventString.split('|').map((value, index) => {
                                        return <TableCell key={`${rowKey}${index}`}
                                                          style={{textAlign: index > 0 ? "right" : "left"}}>
                                            {(value === '0') ? 'Draft' : value}
                                        </TableCell>
                                    })}
                                </TableRow>
                            })
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    } else {
        return (
            <Typography variant="body1" color="inherit" style={{marginLeft: 15, paddingTop: 20}}>
                No contract history found
            </Typography>
        )
    }
}