import React, {useEffect, useState} from "react";
import {RosterGrid} from "../components/RosterGrid";
import {apiGet, apiPost, getUserName} from "../utils";
import Grid from "@material-ui/core/Grid";
import {DropPlayerDialog} from "../components/DropPlayerDialog";

export const HomeContainer = () => {
    const [rosterRows, setRosterRows] = useState([]);
    const [spent, setSpent] = useState(0);
    const [loading, setLoading] = useState(true);
    const [dropDialogOpen, setDropDialogOpen] = useState(false);
    const [dropRow, setDropRow] = useState({});

    const fetchPayingContracts = () => {
        setRosterRows([]);
        apiGet(`contract/owned/${getUserName()}`, (ownedData) => {
            let sum = (ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b));
            apiGet(`contract/penalty/${getUserName()}`, (penaltyData) => {
                setRosterRows(ownedData.data.concat(penaltyData.data));
                sum += (penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b));
                setSpent(sum);
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        fetchPayingContracts();
    }, []);

    const handleOpenDropDialog = (row) => {
        setDropRow(row);
        setDropDialogOpen(true);
    };

    const handleCloseDropDialog = () => {
        setDropDialogOpen(false);
    };

    const handleDropPlayer = () => {
        setLoading(true);
        setDropDialogOpen(false);
        const dropPostBody = {
            playerYahooId: dropRow.yahoo_id,
            managerName: getUserName()
        }
        //TODO: conditionally call drop/predraft if applicable...
        apiPost(`contract/drop`, dropPostBody, () => {
            fetchPayingContracts();
        });
    };

    return (
        <div style={{margin: 50}}>
            <Grid container justifyContent="center">
                <RosterGrid rows={rosterRows} spent={spent} loading={loading} showActions={true}
                            onDropButtonClick={handleOpenDropDialog}/>
                <DropPlayerDialog open={dropDialogOpen} handleClose={handleCloseDropDialog} dropRow={dropRow}
                                  handleDropPlayer={handleDropPlayer}/>
            </Grid>
        </div>
    );
}