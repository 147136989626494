import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {formatTimestamp} from "../utils";
import {CircularProgress} from "@material-ui/core";
import {CheckCircle} from "@material-ui/icons";

export const BiddingDialog = ({open, handleClose, biddingRow, previousBids, userBankTotals, bidAmount, maxBidAmount, onBidAmountChange, onMaxBidAmountChange, bidError, helperText, maxBidHelperText, onSubmitMaxBidUpdate, onSubmitBid, onSubmitImOutBid, updatable, updating, updated}) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="place-bid-dialog">
                {`${biddingRow.first_name} ${biddingRow.last_name} - ${biddingRow.pos} - ${biddingRow.team}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Previous bids:
                </DialogContentText>
                <TableContainer>
                    <Table>
                        <TableBody>
                            {previousBids.map((bid) => (
                                <TableRow key={`bid${bid.id}`}>
                                    <TableCell>{bid.manager_name}</TableCell>
                                    <TableCell>{bid.amount === 0? 'OUT' : bid.amount}</TableCell>
                                    <TableCell>{formatTimestamp(bid.time)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogContentText style={{paddingTop: 40}}>
                    Your auto-bid max (optional):
                </DialogContentText>
                <TextField id="maxBidAmountField" label="Amount" variant="outlined" type="number"
                           value={maxBidAmount ? maxBidAmount : ''}
                           onChange={onMaxBidAmountChange} helperText={maxBidHelperText}/>
                <Button variant="contained" type="submit" color="primary" style={{marginLeft: 10, verticalAlign: "top"}}
                        onClick={onSubmitMaxBidUpdate} disabled={!updatable}>Update</Button>
                {updating && <CircularProgress size="2rem" style={{marginLeft: 10}}/>}
                {updated && <CheckCircle color="secondary" style={{marginLeft: 10, marginTop: 5}}/>}
                <DialogContentText style={{paddingTop: 40}}>
                    Your current bank:
                </DialogContentText>
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>Used:</TableCell>
                                <TableCell>{userBankTotals.spent}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Available:</TableCell>
                                <TableCell>{userBankTotals.available}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <DialogContentText style={{paddingTop: 40}}>
                    Place bid:
                </DialogContentText>
                <TextField id="bidAmountField" label="Amount" variant="outlined" type="number" value={bidAmount}
                           onChange={onBidAmountChange} error={bidError} helperText={helperText}/>
                <Button variant="contained" type="submit" color="primary" style={{marginLeft: 10}} onClick={onSubmitBid} disabled={bidError || updatable}>Submit</Button>
                <Button variant="contained" type="submit" color="primary" style={{marginLeft: 10}} onClick={onSubmitImOutBid}>I'm out</Button>
            </DialogContent>
        </Dialog>
    );
}