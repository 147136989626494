import React, {useState} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {config} from "../config";
import {IconButton} from "@material-ui/core";
import {RotateLeft} from "@material-ui/icons";
import {getUserName} from "../utils";

export const ManagerPicker = ({fetchFunction, preHandler, includeSelf = true}) => {
    const [anchorElManager, setAnchorElManager] = useState(null);
    const [selectedManagerIndex, setSelectedManagerIndex] = useState(null);

    const handleClickManager = (event) => {
        setAnchorElManager(event.currentTarget);
    };

    const handleManagerMenuItemClick = (event, index) => {
        setSelectedManagerIndex(index);
        setAnchorElManager(null);
    };

    const handleClose = () => {
        setAnchorElManager(null);
    };

    const handleSubmit = () => {
        preHandler();
        fetchFunction(config.managerNames[selectedManagerIndex]);
    };

    const includeManagerName = (name) => {
        return includeSelf || (name !== getUserName());
    };

    return (
        <div style={{paddingTop: 15, paddingBottom: 15}}>
            Manager:
            <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}} onClick={handleClickManager}>{config.managerNames[selectedManagerIndex]}</Button>
            <Menu id="managerPickerMenu" anchorEl={anchorElManager} keepMounted open={Boolean(anchorElManager)}
                  onClose={handleClose}>
                {config.managerNames.map((name, index) => (
                    includeManagerName(name) &&
                    <MenuItem key={name} selected={index === selectedManagerIndex}
                              onClick={(event) => handleManagerMenuItemClick(event, index)}>{name}</MenuItem>
                ))}
            </Menu>
            <Button variant="contained" color="primary" style={{height: 25}} onClick={handleSubmit}>Select</Button>
            <IconButton color="inherit" style={{height: 25}} onClick={handleSubmit}>
                <RotateLeft/>
            </IconButton>
        </div>
    );
}