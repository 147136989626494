import React, {useEffect, useState} from "react";
import {apiGet} from "../utils";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {WinsPoolResultsGrid} from "../components/WinsPoolResultsGrid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const WinsPoolContainer = () => {
    const [seasonYears, setSeasonYears] = useState([]);
    const [picks, setPicks] = useState([]);
    const [gameResults, setGameResults] = useState({});
    const [anchorElSeason, setAnchorElSeason] = useState(null);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchWinsSeasonYears = () => {
        setLoading(true);
        apiGet(`wins/seasons`, (data) => {
            setSeasonYears(data.data ? data.data.map(s => s.year) : []);
            setSelectedSeasonIndex(data.data.length - 1);
            setLoading(false);
        });
    };

    const fetchWinsPoolPicks = (season = null) => {
        setLoading(true);
        const urlSeasonParam = (season != null) ? `/season/${season}` : '';
        apiGet(`wins/picks${urlSeasonParam}`, (data) => {
            setPicks(data.data ? data.data : []);
            setLoading(false);
        });
    };

    const fetchGameResults = (season = null) => {
        setLoading(true);
        const urlSeasonParam = (season != null) ? `/season/${season}` : '';
        apiGet(`wins/results${urlSeasonParam}`, (data) => {
            setGameResults(data.data ? data.data : {});
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchWinsSeasonYears();
        fetchWinsPoolPicks();
        fetchGameResults();
    }, []);

    const handleClickSeason = (event) => {
        setAnchorElSeason(event.currentTarget);
    };

    const handleSeasonMenuItemClick = (event, index) => {
        setSelectedSeasonIndex(index);
        setAnchorElSeason(null);
        fetchWinsPoolPicks(seasonYears[index]);
        fetchGameResults(seasonYears[index]);
    };

    const handleClose = () => {
        setAnchorElSeason(null);
    };

    return (
        <Grid container justifyContent="center">
            <div style={{margin: 50}}>
                <div style={{textAlign: "right", marginBottom: 10}}>
                    Season:
                    <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}}
                            onClick={handleClickSeason}>{seasonYears[selectedSeasonIndex]}</Button>
                    <Menu id="seasonPickerMenu" anchorEl={anchorElSeason} keepMounted open={Boolean(anchorElSeason)}
                          onClose={handleClose}>
                        {seasonYears.map((seasonYear, index) => (
                            <MenuItem key={seasonYear} selected={index === selectedSeasonIndex}
                                      onClick={(event) => handleSeasonMenuItemClick(event, index)}>{seasonYear}</MenuItem>
                        ))}
                    </Menu>
                </div>
                {loading && <LinearProgress color="secondary"/>}
                <WinsPoolResultsGrid picks={picks} gameResults={gameResults}/>
            </div>
        </Grid>
    );
}