import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

export const NewBidGroupDialog = ({open, handleClose, playerList, onPlayerChange, maxBidAmount, onMaxBidAmountChange, onSubmitBidGroup}) => {
    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle id="new-bid-group-dialog">
                New Nomination
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Player:
                </DialogContentText>
                <Autocomplete id="playerSelectionField" freeSolo style={{width: 500}} options={playerList}
                              getOptionLabel={(p) => `${p.first_name} ${p.last_name} (${p.pos} - ${p.team})`}
                              onChange={onPlayerChange}
                              renderInput={(params) => (
                                  <TextField {...params} label="Search" margin="normal" variant="outlined"/>
                              )}/>
                <DialogContentText style={{paddingTop: 40}}>
                    Auto-bid up to (optional):
                </DialogContentText>
                <TextField id="maxBidAmountField" label="Amount" variant="outlined" type="number"
                           value={maxBidAmount ? maxBidAmount : ''}
                           onChange={onMaxBidAmountChange}/>
                <br/>
                <Button variant="contained" type="submit" color="primary" style={{float: "right", marginBottom: 16}}
                        onClick={onSubmitBidGroup}>Submit</Button>
            </DialogContent>
        </Dialog>
    );
}