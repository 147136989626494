import React, {useEffect, useState} from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {config} from "../config";
import {apiGet} from "../utils";
import {IconButton} from "@material-ui/core";
import {NavigateBefore, NavigateNext, RotateLeft} from "@material-ui/icons";

export const WeekPicker = ({fetchFunction, preHandler}) => {
    const [currentAuction, setCurrentAuction] = useState({});
    const [anchorElSeason, setAnchorElSeason] = useState(null);
    const [anchorElWeek, setAnchorElWeek] = useState(null);
    const [selectedSeasonIndex, setSelectedSeasonIndex] = useState(null);
    const [selectedWeekIndex, setSelectedWeekIndex] = useState(null);

    useEffect(() => {
        apiGet(`auction/current`, (data) => {
            setSelectedSeasonIndex(config.seasonYears.indexOf(data.data.season_year));
            setSelectedWeekIndex(config.weekNums.indexOf(data.data.week_num));
            setCurrentAuction(data.data);
        });
        fetchFunction();
    }, [fetchFunction]);

    const handleClickSeason = (event) => {
        setAnchorElSeason(event.currentTarget);
    };

    const handleClickWeek = (event) => {
        setAnchorElWeek(event.currentTarget);
    };

    const handleSeasonMenuItemClick = (event, index) => {
        setSelectedSeasonIndex(index);
        setAnchorElSeason(null);
    };

    const handleWeekMenuItemClick = (event, index) => {
        setSelectedWeekIndex(index);
        setAnchorElWeek(null);
    };

    const handleClose = () => {
        setAnchorElSeason(null);
        setAnchorElWeek(null);
    };

    const handlePreviousWeek = () => {
        preHandler();
        if (!(selectedSeasonIndex === 0 && selectedWeekIndex === 0)) {
            if (selectedWeekIndex === 0) {
                fetchFunction(config.seasonYears[selectedSeasonIndex - 1], config.weekNums[config.weekNums.length - 1]);
                setSelectedWeekIndex(config.weekNums.length - 1);
                setSelectedSeasonIndex(selectedSeasonIndex - 1);
            } else {
                fetchFunction(config.seasonYears[selectedSeasonIndex], config.weekNums[selectedWeekIndex - 1]);
                setSelectedWeekIndex(selectedWeekIndex - 1);
            }
        }
    };

    const handleNextWeek = () => {
        preHandler();
        if (!(selectedSeasonIndex === config.seasonYears.indexOf(currentAuction.season_year) && selectedWeekIndex === config.weekNums.indexOf(currentAuction.week_num))) {
            if (selectedWeekIndex === config.weekNums.length - 1) {
                fetchFunction(config.seasonYears[selectedSeasonIndex + 1], config.weekNums[0]);
                setSelectedWeekIndex(0);
                setSelectedSeasonIndex(selectedSeasonIndex + 1);
            } else {
                fetchFunction(config.seasonYears[selectedSeasonIndex], config.weekNums[selectedWeekIndex + 1]);
                setSelectedWeekIndex(selectedWeekIndex + 1);
            }
        }
    };

    const handleSubmit = () => {
        preHandler();
        fetchFunction(config.seasonYears[selectedSeasonIndex], config.weekNums[selectedWeekIndex]);
    };

    const handleReset = () => {
        preHandler();
        setSelectedSeasonIndex(config.seasonYears.indexOf(currentAuction.season_year));
        setSelectedWeekIndex(config.weekNums.indexOf(currentAuction.week_num));
        fetchFunction();
    };

    return (
        <div style={{paddingTop: 15, paddingBottom: 15}}>
            <IconButton color="inherit" style={{height: 25}} onClick={handlePreviousWeek}>
                <NavigateBefore/>
            </IconButton>
            <IconButton color="inherit" style={{height: 25}} onClick={handleNextWeek}>
                <NavigateNext/>
            </IconButton>
            Season:
            <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}} onClick={handleClickSeason}>{config.seasonYears[selectedSeasonIndex]}</Button>
            <Menu id="seasonPickerMenu" anchorEl={anchorElSeason} keepMounted open={Boolean(anchorElSeason)}
                  onClose={handleClose}>
                {config.seasonYears.map((seasonYear, index) => (
                    <MenuItem key={seasonYear} selected={index === selectedSeasonIndex}
                              onClick={(event) => handleSeasonMenuItemClick(event, index)}>{seasonYear}</MenuItem>
                ))}
            </Menu>
            Week:
            <Button style={{backgroundColor: "lightgray", height: 25, marginLeft: 10, marginRight: 10}} onClick={handleClickWeek}>{config.weekNums[selectedWeekIndex]}</Button>
            <Menu id="weekPickerMenu" anchorEl={anchorElWeek} keepMounted open={Boolean(anchorElWeek)}
                  onClose={handleClose}>
                {config.weekNums.map((weekNum, index) => (
                    <MenuItem key={weekNum} value={weekNum} selected={index === selectedWeekIndex}
                              onClick={(event) => handleWeekMenuItemClick(event, index)}>{weekNum === 0? 'Draft': weekNum}</MenuItem>
                ))}
            </Menu>
            <Button variant="contained" color="primary" style={{height: 25}} onClick={handleSubmit}>Select</Button>
            <IconButton color="inherit" style={{height: 25}} onClick={handleReset}>
                <RotateLeft/>
            </IconButton>
        </div>
    );
}