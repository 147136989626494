import React, {useCallback, useState} from "react";
import {RosterGrid} from "../components/RosterGrid";
import {apiGet, getUserName} from "../utils";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {ManagerPicker} from "../components/ManagerPicker";

export const TradeContainer = () => {
    const [opponentName, setOpponentName] = useState(null);
    const [rosterRows, setRosterRows] = useState([]);
    const [rosterRowsAfter, setRosterRowsAfter] = useState([]);
    const [opponentRosterRows, setOpponentRosterRows] = useState([]);
    const [opponentRosterRowsAfter, setOpponentRosterRowsAfter] = useState([]);
    const [bankMeBefore, setBankMeBefore] = useState(0);
    const [bankMeAfter, setBankMeAfter] = useState(0);
    const [bankOppBefore, setBankOppBefore] = useState(0);
    const [bankOppAfter, setBankOppAfter] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingOpponent, setLoadingOpponent] = useState(true);

    const fetchPayingContracts = useCallback(() => {
        setLoading(true);
        setRosterRows([]);
        setRosterRowsAfter([]);
        apiGet(`contract/owned/${getUserName()}`, (ownedData) => {
            let total = ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b);
            apiGet(`contract/penalty/${getUserName()}`, (penaltyData) => {
                if (penaltyData.data) {
                    total += (penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b));
                    setBankMeBefore(total);
                    setBankMeAfter(total);
                }
                const rows = ownedData.data.concat(penaltyData.data);
                setRosterRows(rows);
                setRosterRowsAfter(rows);
                setLoading(false);
            });
        });
    }, []);

    const fetchOpponentPayingContracts = useCallback((managerName = null) => {
        setLoadingOpponent(true);
        fetchPayingContracts();
        setOpponentName(managerName);
        setOpponentRosterRows([]);
        setOpponentRosterRowsAfter([]);
        apiGet(`contract/owned/${managerName}`, (ownedData) => {
            let total = ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b);
            apiGet(`contract/penalty/${managerName}`, (penaltyData) => {
                if (penaltyData.data) {
                    total += (penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b));
                    setBankOppBefore(total);
                    setBankOppAfter(total);
                }
                const rows = ownedData.data.concat(penaltyData.data);
                setOpponentRosterRows(rows);
                setOpponentRosterRowsAfter(rows);
                setLoadingOpponent(false);
            });
        });
    }, [fetchPayingContracts]);

    const handleTradeCheckboxClick = (row, checked) => {
        if (row.manager_name === getUserName() && checked) {
            addToOppAfter(row, true);
            removeFromMeAfter(row);
        } else if (row.manager_name === getUserName() && !checked) {
            removeFromOppAfter(row);
            addToMeAfter(row);
        } else if (row.manager_name === opponentName && checked) {
            addToMeAfter(row, true);
            removeFromOppAfter(row);
        } else if (row.manager_name === opponentName && !checked) {
            removeFromMeAfter(row);
            addToOppAfter(row);
        }
    };

    const addToMeAfter = (row, proposed) => {
        const newRow = JSON.parse(JSON.stringify(row));
        if (proposed) newRow.proposed = true;
        const newRosterRowsAfter = [...rosterRowsAfter, newRow];
        setRosterRowsAfter(newRosterRowsAfter);
        setBankMeAfter(bankMeAfter + (row.is_owned ? row.salary : row.penalty));
    };

    const removeFromMeAfter = (row) => {
        const newRosterRowsAfter = rosterRowsAfter.filter((r) => r.key !== row.key);
        setRosterRowsAfter(newRosterRowsAfter);
        setBankMeAfter(bankMeAfter - (row.is_owned ? row.salary : row.penalty));
    };

    const addToOppAfter = (row, proposed) => {
        const newRow = JSON.parse(JSON.stringify(row));
        if (proposed) newRow.proposed = true;
        const newOppRosterRowsAfter = [...opponentRosterRowsAfter, newRow];
        setOpponentRosterRowsAfter(newOppRosterRowsAfter);
        setBankOppAfter(bankOppAfter + (row.is_owned ? row.salary : row.penalty));
    };

    const removeFromOppAfter = (row) => {
        const newOppRosterRowsAfter = opponentRosterRowsAfter.filter((r) => r.key !== row.key);
        setOpponentRosterRowsAfter(newOppRosterRowsAfter);
        setBankOppAfter(bankOppAfter - (row.is_owned ? row.salary : row.penalty));
    };

    const preHandler = () => {
        setLoadingOpponent(true);
    };

    return (
        <div>
            <Grid container justifyContent="center">
                <table>
                    <tbody>
                    <tr>
                        <td/>
                        <td align="right">
                            <ManagerPicker fetchFunction={fetchOpponentPayingContracts} preHandler={preHandler} includeSelf={false}/>
                        </td>
                    </tr>
                    {opponentName &&
                        <tr>
                            <td><Typography variant="h6" style={{margin: 10}}>Before</Typography></td>
                            <td><Typography variant="h6" style={{margin: 10}}>After</Typography></td>
                        </tr>}
                    {opponentName &&
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={rosterRows} spent={bankMeBefore} loading={loading} showTrading={true}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={rosterRowsAfter} spent={bankMeAfter} loading={loading}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                        </tr>}
                    {opponentName &&
                        <tr>
                            <td><Typography variant="h6" style={{margin: 10}}>{opponentName} Before</Typography></td>
                            <td><Typography variant="h6" style={{margin: 10}}>{opponentName} After</Typography></td>
                        </tr>}
                    {opponentName &&
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={opponentRosterRows} spent={bankOppBefore} loading={loadingOpponent} showTrading={true}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={opponentRosterRowsAfter} spent={bankOppAfter} loading={loadingOpponent}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </Grid>
        </div>
    );
}