import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {NavigationTabs} from "../components/NavigationTabs";
import {ProfileMenu} from "../components/ProfileMenu";

const defaultTabs = [
    {label: 'Home', to: '/home'},
    {label: 'Rosters', to: '/rosters'},
    {label: 'Auction', to: '/auction'},
    {label: 'Trade', to: '/trade'},
    // {label: 'Pre-Draft', to: '/predraft'},
    // {label: 'Draft', to: '/draft'},
    {label: 'History', to: '/history'},
    {label: 'Wins', to: '/wins'}
];

// const adminTabs = [...defaultTabs, {label: 'Draft Trade', to: '/drafttrade'}, {label: 'Admin', to: '/admin'}];
const adminTabs = [...defaultTabs, {label: 'Admin', to: '/admin'}];

export const NavigationContainer = ({managerName, setManagerName, isAdmin, setIsAdmin}) => {
    let tabs = isAdmin ? adminTabs : defaultTabs;
    const indexOfTab = (to) => {
        return tabs.map((t) => {
            return t.to
        }).indexOf(to);
    };
    let history = useHistory();
    const [currentTab, setCurrentTab] = useState(indexOfTab(history.location.pathname));
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const handleOpenMenu = (event) => {
        setProfileMenuAnchorEl(event.target);
        setProfileMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setProfileMenuOpen(false);
    };

    const onClickLogout = () => {
        setProfileMenuOpen(false);
        history.push('/');
        setManagerName(null);
        setIsAdmin(false);
    };

    return (
        <AppBar position="static">
            <Toolbar variant="dense">
                <Typography variant="h6" color="inherit">
                    FFED
                </Typography>
                <div style={{flexGrow: 1}}/>
                {managerName &&
                    <NavigationTabs tabs={tabs} currentTab={currentTab} handleChange={handleChange}/>
                }
                <div style={{flexGrow: 1}}/>
                {managerName &&
                    <ProfileMenu managerName={managerName} isAdmin={isAdmin} anchorEl={profileMenuAnchorEl}
                                 profileMenuOpen={profileMenuOpen} handleOpenMenu={handleOpenMenu}
                                 handleCloseMenu={handleCloseMenu} onClickLogout={onClickLogout}/>
                }
            </Toolbar>
        </AppBar>
    );
}