import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {NavLink} from "react-router-dom";
import React from "react";

export const NavigationTabs = ({tabs, currentTab, handleChange}) => {
    return (
        <Tabs value={(currentTab && currentTab >= 0) ? currentTab : 0} onChange={handleChange} centered>
            {tabs.map((tab) => {
                return <Tab key={tab.label} label={tab.label} component={NavLink} to={tab.to}/>
            })}
        </Tabs>
    );
}