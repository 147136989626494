import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export const ProfileMenu = ({managerName, isAdmin, anchorEl, profileMenuOpen, handleOpenMenu, handleCloseMenu, onClickLogout}) => {
    return (
        <div>
            <Typography variant="subtitle1" color="inherit" display="inline">
                {managerName}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleOpenMenu}>
                <AccountCircle/>
            </IconButton>
            <Menu id="profile-menu" anchorEl={anchorEl} open={profileMenuOpen} onClose={handleCloseMenu}>
                {isAdmin && <MenuItem>Log in as</MenuItem>}
                <MenuItem onClick={onClickLogout}>Log out</MenuItem>
            </Menu>
        </div>
    );
}