import {config} from "../config";
import moment from "moment-timezone";

export const login = (json) => {
    console.log(`Logging in user ${json.user.display_name}`);
    localStorage.setItem('token', JSON.stringify(json.token));
    localStorage.setItem('user', JSON.stringify(json.user));
};

export const isLoggedIn = () => {
    return !!localStorage.getItem('user');
};

export const getUserJson = () => {
    if (isLoggedIn()) {
        return JSON.parse(localStorage.getItem('user'));
    } else {
        return null;
    }
};

export const getUserName = () => {
    if (isLoggedIn()) {
        return JSON.parse(localStorage.getItem('user'))['name'];
    } else {
        return null;
    }
};

export const isUserAdmin = () => {
    if (isLoggedIn()) {
        return JSON.parse(localStorage.getItem('user'))['is_admin'];
    } else {
        return null;
    }
};

export const logout = () => {
    console.log('Logging out');
    localStorage.clear();
    apiPost(`auth/logout`, {}, (data) => {
        console.log(data.message);
    });
};

export const formatTimestamp = (tsString) => {
    const m = moment(tsString);
    const userTZ = getUserJson()['time_zone'];
    return m.tz(userTZ).format('MM/DD/YY hh:mm:ss a z');
}

export const formatTimestampWithDOW = (tsString) => {
    const m = moment(tsString);
    const userTZ = getUserJson()['time_zone'];
    return m.tz(userTZ).format('ddd MM/DD h:mm a z');
}

export const formatDate = (tsString) => {
    const m = moment(tsString);
    const userTZ = getUserJson()['time_zone'];
    return m.tz(userTZ).format('YYYY-MM-DD');
}

export const currentDayOfWeek = () => {
    const userTZ = getUserJson()['time_zone'];
    return moment().tz(userTZ).day();
}

const authHeader = () => {
    const token = localStorage.getItem('token');
    return token ? `Bearer ${token.replaceAll('"', '')}` : null;
};

export const apiGet = (url, callback) => {
    fetch(`${config.apiURL}/${url}`, {
        headers: {
            'Authorization': authHeader()
        }
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        })
        .catch((e) => {
            console.log(e.message);
        });
};

export const apiPost = (url, body, callback) => {
    fetch(`${config.apiURL}/${url}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader()
        }
    })
        .then((response) => response.json())
        .then((data) => {
            callback(data);
        })
        .catch((e) => {
            console.log(e.message);
        });
};