import React, {useEffect, useState} from 'react';
import {Switch, Route} from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import {SpreadsheetContainer} from "./containers/Spreadsheet";
import {AuctionContainer} from "./containers/Auction";
import {TradeContainer} from "./containers/Trade";
// import {PreDraftContainer} from "./containers/PreDraft";
// import {DraftContainer} from "./containers/Draft";
import {DraftTradeContainer} from "./containers/DraftTrade";
import {HistoryContainer} from "./containers/History";
import {WinsPoolContainer} from "./containers/WinsPool";
import {HomeContainer} from "./containers/Home";
import {AdminContainer} from "./containers/Admin";
import {LoginContainer} from "./containers/Login";
import {NavigationContainer} from "./containers/Navigation";
import {FooterContainer} from "./containers/Footer";
import {getUserName, isUserAdmin} from "./utils";

export const App = () => {
    const [managerName, setManagerName] = useState(getUserName());
    const [isAdmin, setIsAdmin] = useState(isUserAdmin());

    useEffect(() => {
        setManagerName(getUserName());
        setIsAdmin(isUserAdmin());
    }, [managerName, isAdmin]);

    return (
        <div>
            <NavigationContainer managerName={managerName} setManagerName={setManagerName}
                                 isAdmin={isAdmin} setIsAdmin={setIsAdmin}/>
            <Switch>
                <PrivateRoute path="/rosters" component={SpreadsheetContainer}/>
                <PrivateRoute path="/auction" component={AuctionContainer}/>
                <PrivateRoute path="/trade" component={TradeContainer}/>
                {/*<PrivateRoute path="/predraft" component={PreDraftContainer}/>*/}
                {/*<PrivateRoute path="/draft" component={DraftContainer}/>*/}
                <PrivateRoute path="/drafttrade" component={DraftTradeContainer}/>
                <PrivateRoute path="/history" component={HistoryContainer}/>
                <PrivateRoute path="/wins" component={WinsPoolContainer}/>
                <PrivateRoute path="/home" component={HomeContainer}/>
                <PrivateRoute path="/admin" component={AdminContainer}/>
                <Route path="/">
                    <LoginContainer setManagerName={setManagerName}/>
                </Route>
            </Switch>
            <FooterContainer/>
        </div>
    );
}
