import React, {useCallback, useState} from "react";
import {RosterGrid} from "../components/RosterGrid";
import {apiGet, apiPost} from "../utils";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {ManagerPicker} from "../components/ManagerPicker";
import Button from "@material-ui/core/Button";

export const DraftTradeContainer = () => {
    const [nameOne, setNameOne] = useState(null);
    const [opponentName, setOpponentName] = useState(null);
    const [rosterRows, setRosterRows] = useState([]);
    const [rosterRowsAfter, setRosterRowsAfter] = useState([]);
    const [opponentRosterRows, setOpponentRosterRows] = useState([]);
    const [opponentRosterRowsAfter, setOpponentRosterRowsAfter] = useState([]);
    const [bankMeBefore, setBankMeBefore] = useState(0);
    const [bankMeAfter, setBankMeAfter] = useState(0);
    const [bankOppBefore, setBankOppBefore] = useState(0);
    const [bankOppAfter, setBankOppAfter] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingOpponent, setLoadingOpponent] = useState(true);
    const [trades, setTrades] = useState([]);

    const fetchPayingContractsOne = useCallback((managerName = null) => {
        setLoading(true);
        setNameOne(managerName);
        setTrades([]);
        setRosterRows([]);
        setRosterRowsAfter([]);
        apiGet(`contract/owned/${managerName}`, (ownedData) => {
            let total = ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b);
            apiGet(`contract/penalty/${managerName}`, (penaltyData) => {
                if (penaltyData.data) {
                    total += (penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b));
                    setBankMeBefore(total);
                    setBankMeAfter(total);
                }
                const rows = ownedData.data.concat(penaltyData.data);
                setRosterRows(rows);
                setRosterRowsAfter(rows);
                setLoading(false);
            });
        });
    }, []);

    const fetchPayingContractsTwo = useCallback((managerName = null) => {
        setLoadingOpponent(true);
        setOpponentName(managerName);
        setTrades([]);
        setOpponentRosterRows([]);
        setOpponentRosterRowsAfter([]);
        apiGet(`contract/owned/${managerName}`, (ownedData) => {
            let total = ownedData.data.map(contract => contract.salary).reduce((a, b) => a + b);
            apiGet(`contract/penalty/${managerName}`, (penaltyData) => {
                if (penaltyData.data) {
                    total += (penaltyData.data.map(contract => contract.penalty).reduce((a, b) => a + b));
                    setBankOppBefore(total);
                    setBankOppAfter(total);
                }
                const rows = ownedData.data.concat(penaltyData.data);
                setOpponentRosterRows(rows);
                setOpponentRosterRowsAfter(rows);
                setLoadingOpponent(false);
            });
        });
    }, []);

    const handleTradeCheckboxClick = (row, checked) => {
        if (row.manager_name === nameOne && checked) {
            addToOppAfter(row, true);
            removeFromMeAfter(row);
            addTradeComponent(row.key, opponentName);
        } else if (row.manager_name === nameOne && !checked) {
            removeFromOppAfter(row);
            addToMeAfter(row);
            removeTradeComponent(row.key, opponentName);
        } else if (row.manager_name === opponentName && checked) {
            addToMeAfter(row, true);
            removeFromOppAfter(row);
            addTradeComponent(row.key, nameOne);
        } else if (row.manager_name === opponentName && !checked) {
            removeFromMeAfter(row);
            addToOppAfter(row);
            removeTradeComponent(row.key, nameOne);
        }
    };

    const addTradeComponent = (contractKey, managerNameTo) => {
        const tradeString = `${contractKey}|${managerNameTo}`;
        console.log(`tradeString: ${tradeString}`);
        const newTrades = [...trades, tradeString];
        setTrades(newTrades);
    };

    const removeTradeComponent = (contractKey, managerNameTo) => {
        const tradeString = `${contractKey}|${managerNameTo}`;
        const newTrades = trades.filter((t) => t !== tradeString);
        setTrades(newTrades);
    };

    const addToMeAfter = (row, proposed) => {
        const newRow = JSON.parse(JSON.stringify(row));
        if (proposed) newRow.proposed = true;
        const newRosterRowsAfter = [...rosterRowsAfter, newRow];
        setRosterRowsAfter(newRosterRowsAfter);
        setBankMeAfter(bankMeAfter + (row.is_owned ? row.salary : row.penalty));
    };

    const removeFromMeAfter = (row) => {
        const newRosterRowsAfter = rosterRowsAfter.filter((r) => r.key !== row.key);
        setRosterRowsAfter(newRosterRowsAfter);
        setBankMeAfter(bankMeAfter - (row.is_owned ? row.salary : row.penalty));
    };

    const addToOppAfter = (row, proposed) => {
        const newRow = JSON.parse(JSON.stringify(row));
        if (proposed) newRow.proposed = true;
        const newOppRosterRowsAfter = [...opponentRosterRowsAfter, newRow];
        setOpponentRosterRowsAfter(newOppRosterRowsAfter);
        setBankOppAfter(bankOppAfter + (row.is_owned ? row.salary : row.penalty));
    };

    const removeFromOppAfter = (row) => {
        const newOppRosterRowsAfter = opponentRosterRowsAfter.filter((r) => r.key !== row.key);
        setOpponentRosterRowsAfter(newOppRosterRowsAfter);
        setBankOppAfter(bankOppAfter - (row.is_owned ? row.salary : row.penalty));
    };

    const preHandlerOne = () => {
        setLoading(true);
    };

    const preHandlerTwo = () => {
        setLoadingOpponent(true);
    };

    const submitTrade = () => {
        setLoading(true);
        setLoadingOpponent(true);
        console.log(`trades: ${trades.length}`);
        const tradePostBody = {
            tradeStrings: trades
        }
        apiPost(`contract/trade`, tradePostBody, (data) => {
            setLoading(false);
            setLoadingOpponent(false);
            console.log(`Response: ${JSON.stringify(data.data, null, 2)}`);
        });
    };

    return (
        <div>
            <Grid container justifyContent="center">
                <table>
                    <tbody>
                    <tr>
                        <td align="right">
                            <ManagerPicker fetchFunction={fetchPayingContractsOne} preHandler={preHandlerOne} includeSelf={true}/>
                        </td>
                        <td align="right">
                            <ManagerPicker fetchFunction={fetchPayingContractsTwo} preHandler={preHandlerTwo} includeSelf={true}/>
                        </td>
                    </tr>
                    {nameOne &&
                        <tr>
                            <td><Typography variant="h6" style={{margin: 10}}>{nameOne} Before</Typography></td>
                            <td><Typography variant="h6" style={{margin: 10}}>{nameOne} After</Typography></td>
                        </tr>}
                    {nameOne &&
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={rosterRows} spent={bankMeBefore} loading={loading} showTrading={true}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={rosterRowsAfter} spent={bankMeAfter} loading={loading}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                        </tr>}
                    {opponentName &&
                        <tr>
                            <td><Typography variant="h6" style={{margin: 10}}>{opponentName} Before</Typography></td>
                            <td><Typography variant="h6" style={{margin: 10}}>{opponentName} After</Typography></td>
                        </tr>}
                    {opponentName &&
                        <tr>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={opponentRosterRows} spent={bankOppBefore} loading={loadingOpponent} showTrading={true}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                            <td style={{verticalAlign: 'top'}}>
                                <RosterGrid rows={opponentRosterRowsAfter} spent={bankOppAfter} loading={loadingOpponent}
                                            onTradeBoxClick={handleTradeCheckboxClick}/>
                            </td>
                        </tr>}
                    <tr>
                        <td/>
                        <td align="right">
                            <Button variant="contained" color="primary" style={{height: 25, marginRight: 10, marginTop: 25}} onClick={submitTrade}>Submit Trade</Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </Grid>
        </div>
    );
}